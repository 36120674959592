import DataComponentBase from '@/commons/DataComponentBase';
import BankDetails from '@/components/Banks/BankDetails';
import VerificationDialog from '@/components/CustomVerificationDialog';
import { BankEntity } from '@/components/Banks/BankEntity';


export default {
    name: 'BankList',
    extends: DataComponentBase,
    components: {
        BankDetails,
        VerificationDialog
    },
    props: {
        actions: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            // tableItems: [],
            headerFilters: {
                id: "",
                active: "all",
                name: "",
                display_name: "",
            },
            // selectedItem: {},
            // dialog: {
            //     details: false,
            //     edit: false,
            //     delete: false
            // },
            entityHandler: new BankEntity(this.$log, this.$store),
        }
    },

    //     created() {
    // //        this.initBankList();
    //     },
    //     mounted() {
    //     },

    computed: {
        headers() {
            let headers = [
                {
                    text: this.$i18n.t('common.id'),
                    align: "right",
                    value: "id",
                    class: "width-89",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['id'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t("common.status"),
                    value: "active",
                    class: "width-105",
                    filterType: "selectSimple",
                    items: [
                        { text: this.$i18n.t("common.all"), value: "all" },
                        { text: this.$i18n.t("common.active"), value: "Y" },
                        { text: this.$i18n.t("common.inactive"), value: "N" },
                    ],
                    filter: f => {
                        if (this.headerFilters['active'] != "all") {
                            return f == this.headerFilters['active'];
                        } else {
                            return true;
                        }
                    }
                },
                {
                    text: this.$i18n.t('common.name'),
                    align: "left",
                    value: "name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.displayName'),
                    align: "left",
                    value: "display_name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['display_name'].toLowerCase()) }
                },
            ];

            if (this.actions) {
                headers.push(
                    {
                        text: "",
                        value: "actions",
                        sortable: true,
                        align: "center",
                        class: "action-column-2",
                    }
                )
            }

            return headers;
        },

    },

    methods: {
        // initBankList() {
        //     this.tableItems = require('@/data/banks.json').data;
        // },

        // openDialogNew() {
        //     this.selectedItem = {};
        //     this.dialog.edit = false;
        //     this.dialog.details = true;
        // },
        // openDialogDetails(item) {
        //     this.selectedItem = JSON.parse(JSON.stringify(item));
        //     this.dialog.edit = true;
        //     this.dialog.details = true;
        // },
        // openDeleteDialog(item) {
        //     this.selectedItem = JSON.parse(JSON.stringify(item));
        //     this.dialog.delete = true;
        // },
        // closeDialog() {
        //     this.dialog.details = false;
        //     this.dialog.edit = false;
        //     this.dialog.delete = false;
        //     this.initBankList();
        // },

        // deleteBank(id) {
        // },

        getStatus(isActive) {
            switch (isActive) {
                case "Y":
                    return { color: "success", text: "common.active" };
                    break;
                case "N":
                    return { color: "#525252", text: "common.inactive" };
                    break;
                default:
                    return { color: "error", text: "common.na" };
                    break;
            }
        },
    },

    watch: {
        // 'dialog.details'() {
        //     if (!this.dialog.details) {
        //         this.closeDialog();
        //     }
        // },
    }

}
