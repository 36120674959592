import ProductList from '@/components/Products/ProductList';
import DialogBase from '@/commons/DialogBase';
import { BankEntity } from '@/components/Banks/BankEntity';


export default {
    name: 'BankDetails',
    extends: DialogBase,
    components: {
        ProductList,
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        entity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            // isActive: true,
            form: {
                general: true,
                table: true,
            },
            // tableItems: [],
            headerFilters: {
                active: "all",
                firmName: "",
                name: "",
                comment: "",
            },
            // selected: this.selectedItems,
            entityHandler: new BankEntity(this.$log, this.$store),
        }
    },

    created() {
    },

    computed: {
        isEdit() {
            return this.value.edit;
        },
        isOpenDetails() {
            return this.value.details;
        }
    },

    methods: {
        openDialogDetails(entity) {
            this.value.edit = true;
            this.value.details = true;
            this.loadEntityData(entity);
        },

        loadEntityData(entity) {
            this.entityHandler.getEntity(entity).then(entityData => {
                this.editedEntity = this.entityHandler.createEntityObj(entityData);
                // this.entityHandler.productList(entity).then(entityData => {
                //     this.editedEntity.products = entityData.data.data;
                // });
            });
            // this.$forceUpdate();
        },
    },

    watch: {
        isOpenDetails() {
            if (this.isOpenDetails) {
                this.form.general = true;
                this.form.table = true;
                if (this.isEdit) {
                    this.openDialogDetails(this.entity);
                } else {
                    this.openDialogNew(this.entity);
                }

                //this.$nextTick(() => this.$refs.agent_form_firm_name.focus());

            }
        },
        // value() {
        // selected() {
        //     this.$emit('update:selectedItems', this.selected);
        // },
        // selectedItems() {
        //     this.selected = this.selectedItems;
        // },
        //     if (this.value) {
        //         this.form.general = true;
        //         this.form.table = true;

        //         this.initIsActive();
        //     }
        // },
        // isActive() {
        //     if (this.isActive) {
        //         this.selectedItem.active == 'Y'
        //     } else {
        //         this.selectedItem.active == 'N'
        //     }
        // }
    }

}
